import React from 'react';
import Link from 'next/link';

import { parsePageUrl } from 'utils/stringHelpers';

import { ListItem } from './atoms/ListItem';
import { LinkName } from './atoms/LinkName';

interface Props {
    urlKey: string;
    name: string;
    url: string;
    as: string;
    isActive: boolean;
    isSelected?: boolean;
    highlight?: boolean;
}

export const MenuLink: React.FC<Props> = ({ urlKey, name, url, as, isActive, highlight }) => {
    return (
        <ListItem>
            <Link as={as} href={parsePageUrl(url, urlKey, '', '')} passHref={true}>
                <LinkName highlight={highlight} active={isActive}>
                    {name}
                </LinkName>
            </Link>
        </ListItem>
    );
};
