import styled from 'styled-components';

import { fromSmall } from 'styleUtils/mediaQueries';
import { gridColumn, gridRow } from 'styleUtils/grid';

import { ContentSection } from './ContentSection';

export const JobSection = styled(ContentSection)`
    ${gridColumn({ start: 1 })}
    ${gridRow({ start: 2 })}

    ${fromSmall`
        ${gridColumn({ start: 3 })}
        ${gridRow({ start: 1 })}
    `}
`;
