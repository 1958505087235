/*
 * Does not work if their are two urls with same words in it
 * Example: /something and /somethingelse.
 * But on this site this is not a issue
 */
export const getIsActiveLink = (pathName: string, key: string): boolean => {
    return (
        pathName.indexOf(key) !== -1 ||
        ((pathName === '/' || pathName === '' || pathName === '/index') && key === 'home')
    );
};
