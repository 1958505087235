import React from 'react';

import ContentLoader from 'react-content-loader';
import { purpleSocial, footerBorder } from 'styles/colors';

export const FooterJobLoading = () => {
    return (
        <ContentLoader
            width={180}
            height={120}
            viewBox="0 0 180 120"
            backgroundColor={purpleSocial}
            foregroundColor={footerBorder}
            uniqueKey="FooterJobLoading" // This is needed to keep consistent with ssr.
        >
            <circle cx="10" cy="10" r="8" />
            <rect x="35" y="5" rx="5" ry="5" width="120" height="10" />
            <circle cx="10" cy="40" r="8" />
            <rect x="35" y="35" rx="5" ry="5" width="120" height="10" />
            <circle cx="10" cy="70" r="8" />
            <rect x="35" y="65" rx="5" ry="5" width="120" height="10" />
            <circle cx="10" cy="100" r="8" />
            <rect x="35" y="95" rx="5" ry="5" width="120" height="10" />
        </ContentLoader>
    );
};
