import React from 'react';
import api from 'api/jobs';
import rootActions from 'redux/rootActions';
import { ContactSection } from './atoms/ContactSection';
import { FlexContainer } from 'components/common/FlexContainer';
import { FooterContactList } from '../FooterContactList';
import { FooterJobList } from '../FooterJobList';
import { FooterMenuList } from '../FooterMenuList';
import { FooterPolicies } from '../FooterPolicies';
import { FooterSocials } from '../FooterSocials';
import { Grid } from './atoms/Grid';
import { Job } from 'types/job';
import { JobSection } from './atoms/JobSection';
import { MenuSection } from './atoms/MenuSection';
import { Padded } from './atoms/Padded';
import { ReduxFetchAction } from 'types/redux';
import { SectionTitle } from './atoms/SectionTitle';
import { TransparentContainer } from 'components/common/TransparentContainer';
import { Wrapper } from './atoms/Wrapper';
import { connect } from 'react-redux';
import { contactInfo, socials, policies } from 'config/config.json';
import { menuItems } from 'config/menuItems';
import { FooterJobLoading } from '../FooterJobLoading';
import { isEmptyObject } from 'utils/typeHelpers';

interface FooterProps {
    jobs: {
        data: {
            [key: string]: Job;
        };
        count: number;
        total: number;
        all: boolean;
    };
    fetchJobsError(
        error:
            | string
            | {
                  code: number;
                  message: string;
              },
    ): void;
    fetchJobsSuccess(jobs: { data: Job[] }): void;
}

const FooterComponent = (props: FooterProps): JSX.Element => {
    const { jobs, fetchJobsSuccess, fetchJobsError } = props;

    React.useEffect(() => {
        if (!jobs.all && jobs.count < 4) {
            api.all().then(
                (response: { data: Job[] }) => {
                    // TODO: Filter in backend
                    const filteredData = response.data.filter((job: Job) => {
                        return job.id !== 650;
                    });

                    const newData = {
                        ...response,
                        data: filteredData,
                        count: filteredData.length,
                    };

                    fetchJobsSuccess(newData);
                },
                (error) => {
                    fetchJobsError(error);
                },
            );
        }
    }, []);

    return (
        <Wrapper>
            <FlexContainer>
                <TransparentContainer>
                    <Padded>
                        <Grid>
                            <ContactSection>
                                <SectionTitle>Developers.nl</SectionTitle>
                                <FooterContactList {...contactInfo} />
                            </ContactSection>
                            <MenuSection>
                                <SectionTitle>Menu</SectionTitle>
                                <FooterMenuList menuItems={menuItems} />
                            </MenuSection>
                            <JobSection>
                                <SectionTitle>Vacatures</SectionTitle>
                                {isEmptyObject(jobs.data) && <FooterJobLoading />}
                                <FooterJobList {...jobs} />
                            </JobSection>
                        </Grid>
                        <FooterSocials socials={socials} />
                        <FooterPolicies policies={policies} />
                    </Padded>
                </TransparentContainer>
            </FlexContainer>
        </Wrapper>
    );
};

export const Footer = connect(
    (state) => {
        return {
            jobs: state.jobs || {},
        };
    },
    (dispatch) => {
        return {
            fetchJobsSuccess: (jobs: any): ReduxFetchAction => dispatch(rootActions.fetchJobsSuccess(jobs)),
            fetchJobsError: (jobs: any): ReduxFetchAction => dispatch(rootActions.fetchJobsError(jobs)),
        };
    },
)(FooterComponent);
