import { Container } from 'reactstrap';
import styled from 'styled-components';
import { toMedium } from 'styleUtils/mediaQueries';

export const TransparentContainer = styled(Container)`
    background: none;
    position: relative;
    padding: 0;
    ${toMedium`
    margin: 0 1rem;
  `}
`;
