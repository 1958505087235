import { FETCH_HOME_ERROR, FETCH_HOME_SUCCESS } from './types';
import { ReduxFetchAction } from '../../types/redux';

const fetchHomeSuccess = (data): ReduxFetchAction => {
    return {
        type: FETCH_HOME_SUCCESS,
        payload: data,
    };
};

const fetchHomeError = (error): ReduxFetchAction => {
    return {
        type: FETCH_HOME_ERROR,
        payload: error,
    };
};

export default {
    fetchHomeSuccess,
    fetchHomeError,
};
