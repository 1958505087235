import React from 'react';
import Icon from '@mdi/react';
import Link from 'next/link';

import { white } from 'styles/colors';

import { Content } from './atoms/Content';
import { Anchor } from './atoms/Anchor';

const color = white;
const size = 0.8;

interface Props {
    icon: string;
    url: string;
    rel?: string;
    target?: string;
    internal?: boolean;
    as?: string;
}
const IconLinkContent: React.FC = ({ children }) => <Content>{children}</Content>;

export const IconLink: React.FC<Props> = (props) => {
    const { url, internal = false, as = url, rel = 'noopener noreferrer', target = '_blank', icon } = props;

    if (internal) {
        return (
            <Link href={url} as={as}>
                <IconLinkContent>
                    <Icon path={icon} size={size} color={color} />
                </IconLinkContent>
            </Link>
        );
    }

    return (
        <Anchor href={url} rel={rel} target={target}>
            <IconLinkContent>
                <Icon path={icon} size={size} color={color} />
            </IconLinkContent>
        </Anchor>
    );
};
