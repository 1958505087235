import React from 'react';
import Icon from '@mdi/react';
import { StyledButton } from './atoms/StyledButton';
import { ButtonColor } from 'types/button';
import { mdiChevronRight } from '@mdi/js';

interface Props extends React.HTMLProps<HTMLButtonElement> {
    color?: ButtonColor;
    more?: boolean;
    onClick?(): any;
    outline?: boolean;
    tabIndex?: number;
}

const Button = React.forwardRef((props: Props, ref) => {
    const { children, color = ButtonColor.orange, more, onClick, outline, tabIndex, ...otherProps } = props;

    return (
        // Button is yelling it does not know all props
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        <StyledButton
            {...otherProps}
            ref={ref}
            color={color}
            more={more}
            onClick={onClick}
            outline={outline}
            tabIndex={tabIndex}
        >
            {children} {more && <Icon path={mdiChevronRight} />}
        </StyledButton>
    );
});

Button.displayName = 'Button';

export { Button };
