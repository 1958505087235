import styled from 'styled-components';

import { toLarge } from 'styleUtils/mediaQueries';

export const ListItem = styled.div`
    padding: 0;
    margin: 0;

    ${toLarge`
    border-bottom: 1px solid #e7e7e7;
    &:last-child {
      border-bottom: none;
    }
    padding: 10px 5px;
  `}
`;
