import styled from 'styled-components';

import { fromLarge } from 'styleUtils/mediaQueries';

export const Padded = styled.div`
    padding: 1.5rem;
    ${fromLarge`
        padding: 6rem 0 1.5rem;
    `};
`;
