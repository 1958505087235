import styled from 'styled-components';

export const SocialIconsWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px 0 10px;
    a {
        margin-right: 20px;
        &:last-child {
            margin-right: 0;
        }
    }
`;
