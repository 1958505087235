import { css, CSSObject } from 'styled-components';
import { subtractionUnit } from './unitCalculationHelpers';
import { path } from 'ramda';
import { isEmptyObject } from '../utils/typeHelpers';
import { unexisty } from '../utils/functional';

const sixteenth = 1 / 16; // eslint-disable-line no-magic-numbers

/**
 * Matches all screens
 */
export const all = 'all';

/**
 * Breakpoint 576px
 */
export const small = 'small';

/**
 * Breakpoint 768px
 */
export const medium = 'medium';

/**
 * Breakpoint 992px
 */
export const large = 'large';

/**
 * Breakpoint 1200px
 */
export const extraLarge = 'extraLarge';

const mediaQueries = {
    [small]: {
        greater: '(min-width: 36em)',
        lesser: `(max-width: ${subtractionUnit('36em', sixteenth)})`,
    },
    [medium]: {
        greater: '(min-width: 48em)',
        lesser: `(max-width: ${subtractionUnit('48em', sixteenth)})`,
    },
    [large]: {
        greater: '(min-width: 62em)',
        lesser: `(max-width: ${subtractionUnit('62em', sixteenth)})`,
    },
    [extraLarge]: {
        greater: '(min-width: 75em)',
        lesser: `(max-width: ${subtractionUnit('75em', sixteenth)})`,
    },
};

export const getMediaQuery = (name: string, type: string): string => {
    return path([name, type], mediaQueries);
};

export const getMediaQueryString = (props: { from?: string; to?: string }): string => {
    const { from, to } = props;

    if (isEmptyObject(props)) {
        return all;
    }

    const mediaFrom = getMediaQuery(from, 'greater');
    const mediaTo = getMediaQuery(to, 'lesser');

    if (unexisty(mediaFrom) && unexisty(mediaTo)) {
        return all;
    }

    if (mediaFrom && mediaTo) {
        return `${mediaFrom} and ${mediaTo}`;
    }

    return mediaFrom || mediaTo;
};

/**
 * Returns the correct mediaquery based on passed from and/or to entry.
 *
 * Examples:
 * mq({ from: medium })(...args) = @media (min-width: 48em) { ...args }
 * mq({ to: medium }) = @media (max-width: 47em) { ...args }
 * mq({ from: medium, to: mediumLarge }) = @media (min-width: 45em) and (max-width: 45em) { ...args }
 *
 * @param {object} props
 * @returns {string | null}
 */
export const mq = (props: { from?: string; to?: string }) => (
    templateLiteral: TemplateStringsArray | CSSObject,
    ...interpolations: any
): string => {
    const mediaQueryString = getMediaQueryString(props);
    const cssProps = css(templateLiteral, ...interpolations);

    return mediaQueryString === all
        ? ((cssProps as unknown) as string)
        : ((css`
              @media ${mediaQueryString} {
                  ${cssProps}
              }
          ` as unknown) as string);
};

export const fromExtraLarge = mq({ from: extraLarge });
export const toExtraLarge = mq({ to: extraLarge });
export const fromLarge = mq({ from: large });
export const toLarge = mq({ to: large });
export const fromMedium = mq({ from: medium });
export const toMedium = mq({ to: medium });
export const fromSmall = mq({ from: small });
