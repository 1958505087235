import styled from 'styled-components';

import { purpleSocial } from 'styles/colors';

export const Content = styled.div`
    align-items: center;
    background-color: ${purpleSocial};
    border-radius: 100px;
    display: inline-flex;
    justify-content: center;
    line-height: 2.25em;
    padding: 0.5em;
    text-align: center;
    transition: transform 0.2s;

    &:hover {
        transform: scale(1.1);
    }

    &:active {
        transform: scale(0.9);
    }
`;
