import styled from 'styled-components';

import { white } from 'styles/colors';

export const PolicyLink = styled.a`
    color: white;
    &:hover {
        color: ${white};
        text-decoration: underline;
    }
`;
