import { unexisty } from '../utils/functional';
import { isNotNumber, isNumber } from '../utils/typeHelpers';
import { isFilledString } from '../utils/stringHelpers';
import { complement } from 'ramda';

type Unit = string | number;
interface UnitValidation {
    invalid?: boolean;
    value?: Unit;
    unit?: Unit;
    values?: Unit[];
}

export const parseUnit = (entryUnit: Unit): UnitValidation => {
    if (unexisty(entryUnit)) {
        return { invalid: true };
    }

    if (isNumber(entryUnit)) {
        return { value: entryUnit, unit: '' };
    }

    const regex = /^(-?[\d.]+)([a-z]+|%)?$/;
    const match = (entryUnit as string).match(regex);

    if (!match) return { invalid: true };

    const [, valueString, unit = ''] = match;
    const value = Number(valueString);

    if (isNotNumber(value)) return { invalid: true };

    return { value, unit };
};

export const parseUnits = (...rawValues: Unit[]): UnitValidation => {
    let unitMemo: Unit = '';
    const valueMemo: Unit[] = [];

    for (const rawValue of rawValues) {
        const { value, unit, invalid } = parseUnit(rawValue);

        if (invalid) return { invalid: true };

        if (isFilledString(unitMemo) && isFilledString(unit) && unit !== unitMemo) {
            return { invalid: true };
        }

        unitMemo = isFilledString(unitMemo) ? unitMemo : unit;
        valueMemo.push(value);
    }

    return {
        values: valueMemo,
        unit: unitMemo,
    };
};

export const calc = (calculator: {
    (a: number, b: number): number;
    (a: number, b: number): number;
    (a: number, b: number): number;
    (a: number, b: number): number;
    (previousValue: any, currentValue: any, currentIndex: number, array: any[]): any;
}) => (...rawValues: any): Unit => {
    const { values, unit, invalid } = parseUnits(...rawValues);

    if (invalid) return null;

    return `${values.reduce(calculator)}${unit}`;
};

export const multiplyUnit = calc((a: number, b: number) => a * b);
export const divideUnit = calc((a: number, b: number) => a / b);
export const additionUnit = calc((a: number, b: number) => a + b);
export const subtractionUnit = calc((a: number, b: number) => a - b);

export const isNegativeUnit = (unit: Unit): boolean => {
    if (unexisty(unit)) return false;
    if (isNumber(unit)) {
        return Math.sign(unit as number) === -1;
    }

    return (unit as string).charAt(0) === '-';
};

export const isPositiveUnit = complement(isNegativeUnit);

const isZeroValue = (value: Unit): boolean => Math.sign(value as number) === 0;

export const isZero = (unit: Unit): Unit | boolean => {
    if (unexisty(unit)) return unit;

    if (isFilledString(unit)) {
        return isZeroValue((unit as string).match(/[\d.]+/)[0]);
    }

    return isNumber(unit) ? isZeroValue(unit) : unit;
};

const toNegativeNumber = (number: number): Unit => number * -1;
const toNegativeString = (string: string): Unit => `-${string}`;

export const toNegative = (unit: string | number): string | number => {
    if (unexisty(unit) || isZero(unit) || isNegativeUnit(unit)) {
        return unit;
    }

    return isNumber(unit) ? toNegativeNumber(unit as number) : toNegativeString(unit as string);
};
