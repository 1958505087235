import { FETCH_CONTACT_ERROR, FETCH_CONTACT_SUCCESS } from './types';
import { ReduxFetchAction } from '../../types/redux';

const fetchContactSuccess = (data): ReduxFetchAction => {
    return {
        type: FETCH_CONTACT_SUCCESS,
        payload: data,
    };
};

const fetchContactError = (error): ReduxFetchAction => {
    return {
        type: FETCH_CONTACT_ERROR,
        payload: error,
    };
};

export default {
    fetchContactSuccess,
    fetchContactError,
};
