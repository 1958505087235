import styled from 'styled-components';

export const Anchor = styled.a`
    text-decoration: none;
    margin-right: 10px;

    &:last-child {
        margin-right: 0;
    }
`;
