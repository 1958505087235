import blogsActions from './blogs/actions';
import contactActions from './contact/actions';
import expertiseActions from './expertise/actions';
import homeActions from './home/actions';
import jobsActions from './jobs/actions';
import whatWeOfferActions from './whatWeOffer/actions';

export default {
    ...blogsActions,
    ...contactActions,
    ...expertiseActions,
    ...homeActions,
    ...jobsActions,
    ...whatWeOfferActions,
};
