import styled from 'styled-components';

import { Exo } from 'styles/fonts';

export const SectionTitle = styled.span`
    color: white;
    font-size: 1.45em;
    display: block;
    font-weight: 400;
    font-family: ${Exo};
    margin-bottom: 20px;
`;
