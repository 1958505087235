import styled from 'styled-components';

import { fromLarge, fromMedium } from 'styleUtils/mediaQueries';
import { gridDisplay, gridTemplateColumns } from 'styleUtils/grid';

export const Grid = styled.div`
    width: 100%;
    ${gridDisplay()}

    grid-column-gap: 10px;

    ${fromMedium`
      ${gridTemplateColumns('1fr 1fr')}
    `};
    ${fromLarge`
      ${gridTemplateColumns('1fr 1fr 1fr')}
    `};
`;
