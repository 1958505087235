import styled from 'styled-components';

import { toLarge, fromLarge } from 'styleUtils/mediaQueries';
import { red, white } from 'styles/colors';
import { Exo } from 'styles/fonts';
import { HighlightProps } from './HighlightProps';
import { defaultLeftMargin, activeHighlightBoxShadow } from './variables';

export const LinkName = styled.div<HighlightProps>`
    background-color: ${white};
    border: none;
    cursor: pointer;
    font-family: ${Exo};
    transition: color 0.1s ease-in-out;
    font-size: 18px;
    border-radius: 50px;
    text-transform: uppercase;
    font-weight: 300;
    padding: 5px 10px;
    margin-left: 20px;
    color: black;

    &:active,
    &:hover {
        outline: none;
        color: ${red};
    }

    ${toLarge`
    margin: 3px 0;
    min-width: 100%;
    text-align: center;
  `};
    ${({ highlight }): string => `margin-left: ${defaultLeftMargin + (highlight ? 8 : 0)}px`};
    ${({ active }): string =>
        active &&
        `
    color: ${red};
  `};
    ${toLarge`
    ${({ active }: HighlightProps): string =>
        active &&
        `
      color: ${red};
      font-weight: 500;
      -webkit-font-smoothing: antialiased;
    `};
  `};

    ${fromLarge`
    ${({ highlight }: HighlightProps): string =>
        highlight &&
        `
        transition: box-shadow 0.2s ease-in-out;
        background-color: ${red};
        color: ${white};
        border-radius: 100px;
        &:hover {
          color: ${white};
          ${activeHighlightBoxShadow};
        }
      `};
  `};
    ${fromLarge`
    ${({ active, highlight }: HighlightProps): string =>
        active &&
        highlight &&
        `
      ${activeHighlightBoxShadow};
    `}
  `}
`;
