import api from './api';
import axios from 'axios';

const one = (id: number): Promise<any> => {
    return api(`/vacancies/${id}`);
};

let axiosSource;
const all = (params = {}): Promise<any> => {
    if (axiosSource) {
        axiosSource.cancel('Only one request allowed at a time.');
    }

    axiosSource = axios.CancelToken.source();

    return api('/vacancies', {
        params: params,
        cancelToken: axiosSource.token,
    });
};

export default {
    all,
    one,
};
