import styled from 'styled-components';

import { fromLarge, toExtraLarge } from 'styleUtils/mediaQueries';

import { Nav } from 'reactstrap';

export const Navigation = styled(Nav)`
    margin: 0;
    padding: 0;
    margin-left: auto;

    ${fromLarge`
    float: right;
  `};

    ${toExtraLarge`
    a > div {
      margin-left: 10px;
      padding: 5px 10px;
    }
  `}
`;
