import React from 'react';
import Link from 'next/link';
import { Router } from 'next/router';

import { menuItems } from 'config/menuItems';
import config from 'config/config.json';
import { getIsActiveLink } from 'utils/links';
import { parsePageUrl } from 'utils/stringHelpers';

import { Collapse } from 'reactstrap';
import { Hamburger } from '../Hamburger';
import { MenuLink } from '../MenuLink';
import { WebsiteHider } from '../WebsiteHider';
import { NavigationBar } from './atoms/NavigationBar';
import { NavigationBarBrand } from './atoms/NavigationBarBrand';
import { Navigation } from './atoms/Navigation';

interface Props {
    pathName: string;
}

export const Header: React.FC<Props> = ({ pathName }) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const toggleNavigation = (): void => {
        setIsOpen(!isOpen);
    };

    const routeChangeStart = (): void => {
        setIsOpen(false);
    };

    const scroll = (): void => {
        setIsOpen(false);
    };

    React.useEffect(() => {
        Router.events.on('routeChangeStart', routeChangeStart);
        window.addEventListener('scroll', scroll);

        return (): void => {
            window.removeEventListener('scroll', scroll);
            Router.events.off('routeChangeStart', routeChangeStart);
        };
    }, []);

    const { home, contact } = config.pages;

    return (
        <header>
            <NavigationBar light expand="lg">
                <NavigationBarBrand>
                    <Link href={parsePageUrl(home.url, home.key, '', '')} as={home.as}>
                        <img src="/images/logo.svg" alt="Developers.nl" />
                    </Link>
                </NavigationBarBrand>
                <Hamburger isOpen={isOpen} onClick={toggleNavigation} />
                <Collapse isOpen={isOpen} navbar>
                    <Navigation id="menu" navbar>
                        {menuItems.map((item) => (
                            <MenuLink
                                key={item.key}
                                urlKey={item.key}
                                name={item.name}
                                url={item.url}
                                as={item.as}
                                isActive={getIsActiveLink(pathName, item.key)}
                            />
                        ))}
                        <MenuLink
                            urlKey={contact.key}
                            name={contact.name}
                            url={contact.url}
                            as={contact.as}
                            isActive={getIsActiveLink(pathName, contact.key)}
                            highlight
                        />
                    </Navigation>
                </Collapse>
            </NavigationBar>
            <WebsiteHider isOpen={isOpen} />
        </header>
    );
};
