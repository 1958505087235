import React from 'react';
import Link from 'next/link';

import { MenuItem } from 'types/menuItem';

import { mdiChevronRight } from '@mdi/js';
import { Menu } from './atoms/Menu';
import { FooterList } from '../FooterList';
import { FooterListItem } from '../FooterListItem';
import { FooterListItemIcon } from '../FooterListItemIcon';

interface Props {
    menuItems: MenuItem[];
}
export const FooterMenuList: React.FC<Props> = ({ menuItems }) => {
    return (
        <Menu>
            <FooterList>
                {menuItems.map(({ key, url, as, name }) => (
                    <FooterListItem key={key}>
                        <FooterListItemIcon path={mdiChevronRight} />
                        <Link href={url} as={as} passHref={true}>
                            <a>{name}</a>
                        </Link>
                    </FooterListItem>
                ))}
            </FooterList>
        </Menu>
    );
};
