import React from 'react';

import { Button } from './atoms/Button';
import { Box } from './atoms/Box';
import { Inner } from './atoms/Inner';

interface Props {
    isOpen: boolean;
    onClick: () => void;
}
export const Hamburger: React.FC<Props> = ({ isOpen, onClick }) => {
    return (
        <Button type="button" onClick={onClick} isOpen={isOpen}>
            <Box>
                <Inner isActive={isOpen} />
            </Box>
        </Button>
    );
};
