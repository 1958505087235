import { FETCH_BLOGS_ERROR, FETCH_BLOGS_SUCCESS, FETCH_BLOG_ERROR, FETCH_BLOG_SUCCESS } from './types';
import { ReduxFetchAction } from '../../types/redux';

const fetchBlogsSuccess = (blogs): ReduxFetchAction => {
    return {
        type: FETCH_BLOGS_SUCCESS,
        payload: blogs,
    };
};

const fetchBlogsError = (error): ReduxFetchAction => {
    return {
        type: FETCH_BLOGS_ERROR,
        payload: error,
    };
};

const fetchBlogSuccess = (blog): ReduxFetchAction => {
    return {
        type: FETCH_BLOG_SUCCESS,
        payload: blog,
    };
};

const fetchBlogError = (error): ReduxFetchAction => {
    return {
        type: FETCH_BLOG_ERROR,
        payload: error,
    };
};

export default {
    fetchBlogsSuccess,
    fetchBlogsError,
    fetchBlogSuccess,
    fetchBlogError,
};
