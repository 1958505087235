import React from 'react';

import { FILE_BASE_URL } from 'variables';
import { Policy } from 'types/policy';

import { PolicyList } from './atoms/PolicyList';
import { PolicyItem } from './atoms/PolicyItem';
import { PolicyLink } from './atoms/PolicyLink';

interface Props {
    policies: Policy[];
}
export const FooterPolicies: React.FC<Props> = ({ policies }) => {
    return (
        <PolicyList>
            {policies.map(({ name, url }, index) => (
                <PolicyItem key={index}>
                    <PolicyLink href={FILE_BASE_URL + url} rel="noopener noreferrer" target="_blank">
                        {name}
                    </PolicyLink>
                </PolicyItem>
            ))}
        </PolicyList>
    );
};
