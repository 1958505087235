import styled from 'styled-components';

import { red } from 'styles/colors';

interface Props {
    isActive: boolean;
}
export const Inner = styled.span<Props>`
    &,
    &::before,
    &::after {
        background-color: ${red};
        width: 40px;
        height: 4px;
        border-radius: 4px;
        position: absolute;
    }

    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    display: block;
    margin-top: -2px;
    transition-property: transform;

    &::before {
        transition: top 0.12s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s,
            transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        top: -10px;
    }

    &::after {
        top: -20px;
        bottom: -10px;
        transition: top 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1) 0.2s, opacity 0.1s linear;
    }

    &::after,
    &::before {
        content: '';
        display: block;
    }

    ${({ isActive }): string =>
        isActive
            ? `
        transform: translate3d(0, -10px, 0) rotate(-45deg);
        transition-delay: 0.22s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        
        &::before {
          top: 0;
          transform: rotate(-90deg);
          transition: top 0.1s cubic-bezier(0.33333, 0, 0.66667, 0.33333) 0.16s,
          transform 0.13s cubic-bezier(0.215, 0.61, 0.355, 1) 0.25s;
        }
        
        &::after {
          top: 0;
          opacity: 0;
          transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          opacity 0.1s linear 0.22s;
        }
      `
            : ''}
`;
