import styled from 'styled-components';

import { footer as footerColor, footerBorder as footerBorderColor } from 'styles/colors';

export const Wrapper = styled.div`
    background: ${footerColor};
    border-top: solid 4px ${footerBorderColor};
    display: flex;
    flex-direction: column;
    margin-top: auto;
`;
