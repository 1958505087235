import styled from 'styled-components';

import { fromLarge } from 'styleUtils/mediaQueries';

import { darkText } from 'styles/colors';

interface WebsiteHiderProps {
    isOpen: boolean;
}
export const WebsiteHider = styled.div<WebsiteHiderProps>`
    display: none;
    ${fromLarge`
        display: none;
    `};
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${darkText};
    width: 100vw;
    height: 100vh;
    ${({ isOpen }: WebsiteHiderProps): string =>
        isOpen &&
        `
        display: block;
        opacity: 0.6;
        filter: alpha(opacity=60); /* For IE8 and earlier */
    `}
`;
