import { FETCH_JOBS_ERROR, FETCH_JOBS_SUCCESS, FETCH_JOB_ERROR, FETCH_JOB_SUCCESS } from './types';
import { ReduxFetchAction } from '../../types/redux';

const fetchJobsSuccess = (jobs): ReduxFetchAction => {
    return {
        type: FETCH_JOBS_SUCCESS,
        payload: jobs,
    };
};

const fetchJobsError = (error): ReduxFetchAction => {
    return {
        type: FETCH_JOBS_ERROR,
        payload: error,
    };
};

const fetchJobSuccess = (job): ReduxFetchAction => {
    return {
        type: FETCH_JOB_SUCCESS,
        payload: job,
    };
};

const fetchJobError = (error): ReduxFetchAction => {
    return {
        type: FETCH_JOB_ERROR,
        payload: error,
    };
};

export default {
    fetchJobsSuccess,
    fetchJobsError,
    fetchJobSuccess,
    fetchJobError,
};
