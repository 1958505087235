export const FontAwesomeFree = 'Font Awesome 5 Free';
export const FontAwesomeBrand = 'Font Awesome 5 Brands';
export const MaterialIcons = 'Material Icons';
export const Exo = "'Exo', sans-serif";
export const Raileway = "'Raileway', sans-serif";

export const fonts = [
    'https://fonts.googleapis.com/css?family=Material+Icons|Exo:300,400,500|Raileway:300,400,500|PT+Sans:400,700&display=swap',
    'https://use.fontawesome.com/releases/v5.7.2/css/all.css',
];
