import React from 'react';

import { Social } from 'types/social';

import { mdiLinkedin, mdiInstagram, mdiMeetup, mdiFacebook, mdiTwitter, mdiHelp } from '@mdi/js';
import { SocialIconsWrapper } from './atoms/SocialIconsWrapper';
import { IconLink } from 'components/common/IconLink';

const getSocialIcon = (name: string): string => {
    switch (name) {
        case 'Facebook':
            return mdiFacebook;
        case 'Instagram':
            return mdiInstagram;
        case 'Meetup':
            return mdiMeetup;
        case 'Twitter':
            return mdiTwitter;
        case 'Linkedin':
            return mdiLinkedin;
        default:
            return mdiHelp;
    }
};

interface Props {
    socials: Social[];
}
export const FooterSocials: React.FC<Props> = ({ socials }) => (
    <SocialIconsWrapper>
        {socials.map(({ name, url }) => (
            <IconLink key={name} icon={getSocialIcon(name)} url={url} />
        ))}
    </SocialIconsWrapper>
);
