import getConfig from 'next/config';

const config = getConfig();

export const FILE_BASE_URL = config.publicRuntimeConfig.fileBaseUrl;
export const API_BASE_URL = config.publicRuntimeConfig.apiBaseUrl;

export const getBaseUrl = (): string => (process.browser ? API_BASE_URL : process.env.BACKEND_URL) + '/api/v1';

export const getHostHeader = (): string => process.env.SERVER_NAME;
