import React from 'react';
import Link from 'next/link';

import { mdiMapMarker, mdiDomain, mdiPhone, mdiEmail, mdiRoutes } from '@mdi/js';
import { Address } from './atoms/Address';
import { FooterList } from '../FooterList';
import { FooterListItem } from '../FooterListItem';
import { FooterListItemIcon } from '../FooterListItemIcon';

interface Props {
    street: string;
    zipCode: string;
    city: string;
    mapsUrl: string;
    phone: string;
    mail: string;
}

export const FooterContactList: React.FC<Props> = ({ street, zipCode, city, mapsUrl, phone, mail }) => {
    return (
        <Address>
            <FooterList>
                <FooterListItem>
                    <FooterListItemIcon path={mdiMapMarker} />
                    <a href={mapsUrl} rel="noopener noreferrer" target="_blank">
                        {street}
                    </a>
                </FooterListItem>
                <FooterListItem>
                    <FooterListItemIcon path={mdiDomain} />
                    <a href={mapsUrl} rel="noopener noreferrer" target="_blank">
                        {zipCode}, {city}
                    </a>
                </FooterListItem>
                <FooterListItem>
                    <FooterListItemIcon path={mdiPhone} />
                    <a href={`tel:${phone}`}>{phone}</a>
                </FooterListItem>
                <FooterListItem>
                    <FooterListItemIcon path={mdiEmail} />
                    <a href={`mailto:${mail}`}>{mail}</a>
                </FooterListItem>
                <FooterListItem>
                    <FooterListItemIcon path={mdiRoutes} />
                    <Link href="/route" passHref={true}>
                        <a>Routebeschrijving</a>
                    </Link>
                </FooterListItem>
            </FooterList>
        </Address>
    );
};
