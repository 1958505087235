import { isString } from 'util';
import sanitize from 'sanitize-html';

export const isFilledString = (value: any): boolean => isString(value) && value.length > 0;

export const capitalizeFirstLetter = (value: string): string => `${value.charAt(0).toUpperCase()}${value.slice(1)}`;

export const toLowerCase = (value: string): string => value.toLowerCase();

export const toUpperCase = (value: string): string => {
    if (typeof value !== 'string') {
        return '';
    }

    return value.toUpperCase();
};

export const stripDashes = (value: string): string => value.replace('-', ' ');

//Temporally fix for job offer text;
export const replaceHtmlCodeWithSymbols = (text: string): string => {
    const characters = [
        { code: '&bull;', replacement: '•' },
        { code: '&#39;', replacement: `'` },
        { code: '&rsquo;', replacement: `'` },
        { code: '&euro;', replacement: '€' },
        { code: '&eacute;', replacement: 'é' },
    ];

    let newText = text;
    characters.forEach((character) => {
        newText = newText.replace(new RegExp(character.code, 'g'), character.replacement);
    });

    return newText;
};

export const parsePageUrl = (page: string, type: string, addition = '', prefix = '/'): string =>
    `${prefix + page}?type=${type}&menuSelected=${type + addition}`;

export const renderHTML = (html: string): { __html: string } => {
    return { __html: html };
};

/**
 * Santize html to prevent XSS attacks
 * @param {string} dirtyString
 */
export const sanitizeHtml = (dirtyString: string): string => {

    const defaultAttributes = sanitize.defaults.allowedAttributes;
    return sanitize(dirtyString, {
        allowedTags: sanitize.defaults.allowedTags.concat(['img']),
        allowedAttributes: {
            ...defaultAttributes,
            img: ['src', 'alt', 'title'],
        },
        allowedSchemes: sanitize.defaults.allowedSchemes.concat(['tel']),
    });
};
