import styled from 'styled-components';

import { headerHeight } from './variables';
import { fromMedium, fromLarge } from 'styleUtils/mediaQueries';

import { NavbarBrand } from 'reactstrap';

export const NavigationBarBrand = styled(NavbarBrand)`
    cursor: pointer;
    margin-right: 0;

    padding-top: 0;
    padding-bottom: 0;
    padding-left: 20px;

    img {
        width: 200px;
        height: ${headerHeight};
    }

    ${fromMedium`
        img {
            width: 250px;
        }
    `}

    ${fromLarge`
        padding: 0;
    `}
`;
