import styled from 'styled-components';
import { ButtonColor, buttonColors, buttonHoverColors } from 'types/button';
import { white } from 'styles/colors';
import { Exo } from 'styles/fonts';
import { LinkProps } from 'next/link';

interface ButtonProps extends LinkProps {
    color: ButtonColor;
    outline?: boolean;
    more?: boolean;
    onClick?(): any;
    tabIndex?: number;
}

export const StyledButton = styled.button<ButtonProps>(({ color, outline, more }) => {
    const buttonColor = buttonColors[color];
    const buttonHoverColor = buttonHoverColors[color];

    return `
    background-color: ${buttonColor};
    border: solid ${buttonColor} 2px;
    transition: background-color 0.3s ease-in-out, border 0.3s ease-in-out, color 0.3s ease-in-out;
    border-radius: 20px;
    padding: 10px 25px;
    color: ${white};
    font-family: ${Exo};
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    font-size: 1rem;
    text-transform: none;
    overflow: visible;
    margin: 0;

    &:hover,
    &:focus {
        background-color: ${buttonHoverColor};
        border: solid ${buttonHoverColor} 2px;
    }

    ${
        outline
            ? `
      border: solid ${buttonColor} 2px;
      background-color: rgba(0,0,0,0);
      color: ${buttonColor};

      &:hover,
      &:focus {
        background-color: ${buttonColor};
        color: ${white};
        outline: none;
      }
    `
            : ''
    }

    ${
        more
            ? `
      .mdi:before {
        font-size: 1.5rem;
        position: relative;
        top: 3px;
        margin-bottom: 5px;
      }

      svg {
        height:  1.5rem;
        width: 1.5rem;
        fill: ${white};
      }
    `
            : ''
    }
  `;
});
