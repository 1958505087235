import { FETCH_EXPERTISE_ERROR, FETCH_EXPERTISE_SUCCESS } from './types';
import { ReduxFetchAction } from '../../types/redux';

const fetchExpertiseSuccess = (data): ReduxFetchAction => {
    return {
        type: FETCH_EXPERTISE_SUCCESS,
        payload: data,
    };
};

const fetchExpertiseError = (error): ReduxFetchAction => {
    return {
        type: FETCH_EXPERTISE_ERROR,
        payload: error,
    };
};

export default {
    fetchExpertiseSuccess,
    fetchExpertiseError,
};
