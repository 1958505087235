export enum ButtonColor {
    orange = 'orange',
    blue = 'blue',
    green = 'green',
}

import { blue, green, orange } from 'styles/colors';
export const buttonColors = {
    orange: orange,
    green: green,
    blue: blue,
};

export const buttonHoverColors = {
    orange: green,
    green: orange,
    blue: green,
};
