import styled from 'styled-components';

import { gridColumn, gridRow } from 'styleUtils/grid';

import { ContentSection } from './ContentSection';

export const ContactSection = styled(ContentSection)`
    ${gridColumn({ start: 1 })}
    ${gridRow({ start: 1 })}
`;
