import styled from 'styled-components';

import { lessTransparentWhite } from '../../../styles/colors';

import Icon from '@mdi/react';

export const FooterListItemIcon = styled(Icon).attrs({
    size: 0.9,
    color: lessTransparentWhite,
})`
    margin-right: 14px;
`;
