import React from 'react';
import Link from 'next/link';

import { Job } from 'types/job';
import { pages } from 'config/config.json';

import { FooterList } from '../FooterList';
import { FooterListItem } from '../FooterListItem';
import { FooterListItemIcon } from '../FooterListItemIcon';
import { mdiChevronRight } from '@mdi/js';
import { Menu } from './atoms/Menu';

interface Props {
    total: number;
    data: {
        [key: string]: Job;
    };
}
export const FooterJobList: React.FC<Props> = (props) => {
    const { data, total } = props;
    const jobs = Object.values(data).slice(0, 4);
    const gotAllJobs = jobs.length === total;
    const more = pages.vacatures;

    return (
        <Menu>
            <FooterList>
                {jobs.map(({ id, title }) => {
                    const url = '/vacatures/[id]';
                    const as = `/vacatures/${id}`;

                    return (
                        <FooterListItem key={id}>
                            <FooterListItemIcon path={mdiChevronRight} />
                            <Link href={url} as={as} passHref={true}>
                                <a>{title}</a>
                            </Link>
                        </FooterListItem>
                    );
                })}
                {!gotAllJobs && (
                    <FooterListItem>
                        <FooterListItemIcon path={mdiChevronRight} />
                        <Link href={more.url} as={more.as} passHref={true}>
                            <a>Meer vacatures</a>
                        </Link>
                    </FooterListItem>
                )}
            </FooterList>
        </Menu>
    );
};
