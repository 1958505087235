import styled from 'styled-components';
import { toLarge } from 'styleUtils/mediaQueries';

export const FlexContainer = styled.div`
    width: 100%;

    ${toLarge`
        display: flex;
        justify-content: center;
        padding: 0 20px;
    `}
`;
