import { FETCH_WHATWEOFFER_ERROR, FETCH_WHATWEOFFER_SUCCESS } from './types';
import { ReduxFetchAction } from '../../types/redux';

const fetchWhatWeOfferSuccess = (data): ReduxFetchAction => {
    return {
        type: FETCH_WHATWEOFFER_SUCCESS,
        payload: data,
    };
};

const fetchWhatWeOfferError = (error): ReduxFetchAction => {
    return {
        type: FETCH_WHATWEOFFER_ERROR,
        payload: error,
    };
};

export default {
    fetchWhatWeOfferSuccess,
    fetchWhatWeOfferError,
};
