import styled from 'styled-components';

import { toLarge } from 'styleUtils/mediaQueries';
import { Exo, Raileway } from 'styles/fonts';

export const Container = styled.div`
    max-width: inherit;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 0;
    font-size: 1em;
    font-family: ${Raileway};
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #343434;
    background-color: white;
    ${toLarge`
    font-size: 0.9em;
  `}

    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        font-family: ${Exo};
    }
`;
