import styled from 'styled-components';

import { fromLarge } from 'styleUtils/mediaQueries';
import { white } from 'styles/colors';
import { headerHeight } from './variables';

import { Navbar } from 'reactstrap';

export const NavigationBar = styled(Navbar)`
    max-height: ${headerHeight};
    z-index: 100;
    background: ${white};

    .headroom--pinned & {
        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
    }
    .headroom--unpinned & {
        box-shadow: none;
    }

    ${fromLarge`
        padding: 10px 30px;
    `}

    .collapse, .collapsing {
        width: 100%;
        background-color: ${white};
    }
`;
