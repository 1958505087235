import styled from 'styled-components';

import { fromMedium } from 'styleUtils/mediaQueries';
import { gridColumn } from 'styleUtils/grid';

import { ContentSection } from './ContentSection';

export const MenuSection = styled(ContentSection)`
    ${gridColumn({ start: 2 })}
    display: none;

    ${fromMedium`
        display: block;
    `}
`;
