import React from 'react';
import { Header } from '../../header/Header';
import { Footer } from 'components/footer/Footer';
import { logPageView } from 'helpers/analytics';
import { Container } from './atoms/Container';
import { Headroom } from './atoms/Headroom';

interface Props {
    children?: React.ReactNode;
    pathName: string;
}

export const Layout: React.FC<Props> = (props: Props) => {
    const { children, pathName } = props;

    React.useEffect(() => {
        logPageView();
    }, [pathName]);

    return (
        <Container>
            <Headroom upTolerance={15} downTolerance={15}>
                <Header pathName={pathName} />
            </Headroom>

            {children}

            <Footer />
        </Container>
    );
};
