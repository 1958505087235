import { getBaseUrl, getHostHeader } from 'variables';
import axios from 'axios';

axios.defaults.headers.common['accept'] = 'application/json';
axios.defaults.baseURL = getBaseUrl();
axios.defaults.responseType = 'json';

if (!process.browser) {
    /*
     * Uitleg van Kaj:
     * Server-side requests moeten naar BACKEND_URL, dat is gelijk aan de DNS van de nginx container, dus; http://nginx:3000.
     * Hier kan geen subdomein aan meegegeven worden. Hierdoor “faken” wij de Host header waardoor de backend alsnog naar de juiste API routes gaat.
     */
    axios.defaults.headers.common['Host'] = getHostHeader();
}

const api = (url, additionalConfig = {}): Promise<any> => {
    return axios({
        url,
        ...additionalConfig,
    }).then((response) => {
        return response.data;
    });
};

export default api;
