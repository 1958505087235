export const gridDisplay = (): string => `
display: -ms-grid;
display: grid;
`;

export const gridTemplateColumns = (value: string): string => `
-ms-grid-columns: ${value};
grid-template-columns: ${value};
`;

export const gridTemplateRows = (value: string): string => `
-ms-grid-rows: ${value};
grid-template-rows: ${value};
`;

export interface GridStartEndAttributes {
    start?: number;
    end?: number;
    ieAddStart?: number;
    ieAddEnd?: number;
}

const gridStartEnd = ({ start, end, ieAddStart = 0, ieAddEnd = 0 }: GridStartEndAttributes, prefix: string): string => {
    let value = '';

    if (start) {
        value = `
            -ms-grid-${prefix}: ${start + ieAddStart};
            grid-${prefix}-start: ${start};
        `;
    }

    if (start && end) {
        value = `${value}
            -ms-grid-${prefix}-span: ${end - 1 + ieAddEnd};
            grid-${prefix}-end: ${end};
        `;
    } else if (end) {
        value = `
            -ms-grid-${prefix}-span: ${end + ieAddEnd};
            grid-${prefix}-end: ${end};
        `;
    }

    return value;
};

export const gridRow = (startEnd: GridStartEndAttributes): string => gridStartEnd(startEnd, 'row');
export const gridColumn = (startEnd: GridStartEndAttributes): string => gridStartEnd(startEnd, 'column');
