import styled from 'styled-components';

import { white } from '../../../styles/colors';

export const FooterListItem = styled.li`
    line-height: 1.7em;

    a {
        color: ${white};
    }
`;
