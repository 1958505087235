import styled from 'styled-components';

import { fromLarge } from 'styleUtils/mediaQueries';

interface Props {
    isOpen: boolean;
}
export const Button = styled.button<Props>`
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    padding-right: 20px;

    &:focus {
        outline: none;
    }

    ${fromLarge`
    display: none;
  `}
`;
